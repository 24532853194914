import React from "react"

const Newsletter = () => {
  return (
    <div className={"newsletter"}>
      <div className={"container"}>
        <h2 className={"padding-bottom-64"}>
          GET IN WITH THE LATEST FORMATS AND INSIGHTS WITH US. <br/>
          <span style={{fontStyle:'italic'}}>SUBSCRIBE TO OUR NEWSLETTER.</span>
        </h2>

        <div id="mc_embed_signup" className={'padding-bottom-200'}>
            <form action="https://epitomecollective.us3.list-manage.com/subscribe?u=0bdd53feff8566c6c26a8ef22&id=7d65046b9e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Enter your email" required/>
                <input type="submit" onClick={(event) => {
                  const form = document.querySelector('#mc-embedded-subscribe-form');
                  if(form.checkValidity()) {

                    form.reset();
                    form.submit();

                    const input = document.querySelector('#mce-EMAIL');
                    input.blur();

                    event.preventDefault();

                    return false;
                  }
                }} value="SUBSCRIBE" name="subscribe" id="mc-embedded-subscribe" className="button"/>
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                  <input type="text" name="b_9a9d5c6fd5dce4bee38ea564a_b488d8ac9b" tabIndex="-1" />
                </div>
          </form>
        </div>

      </div>
    </div>
  )
}

export default Newsletter;
