import React, { useEffect } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ImageWithAnimation from "../components/ImageWithAnimation";
import { textAppearAnimationAll } from "../components/textAppearAnimation"
import TextRepeater from "../components/TextRepeater"
import Newsletter from "../components/newsletter"

const News = ({ data }) => {


  const site = data.wpPage;
  const posts = data.allWpNews.nodes

  useEffect(() => {
    textAppearAnimationAll("h3")
  }, []);

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" slug="blog" />
        <Bio />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout page={'news'} >
      <Seo title="Blog" />

      <Hero fields={site.customFieldsHero} />

      <ol className={'news-list'}>
        {posts.map(post => {
          const title = post.title
          return (
            <li key={post.uri}>
              <div className={"item wp-block-image link"}>
                <AniLink cover to={post.uri} bg="#000" direction="up" duration={1}>
                <ImageWithAnimation src={post.featuredImage.node.localFile.childImageSharp.fluid} />
                  <div className={"desc"}>
                    <h3 className={"subtitle"}>{parse(title)}</h3>
                    <TextRepeater text={post.date} repeat={30}/>
                  </div>
                </AniLink>
              </div>
            </li>
          )
        })}
      </ol>

      <Newsletter/>

    </Layout>
  )
}

export default News

export const pageQuery = graphql`
    query NewsPostArchive {
        wpPage(slug: { eq: "blog" }) {
            title
            slug
            customFieldsHero {
                heroTitle
                heroTitleItalic
                heroContent
            }
        }
        allWpNews(
            sort: { fields: [date], order: DESC }
            limit: 999
        ) {
            nodes {
                uri
                title
                date(formatString: "DD MMMM YYYY")
                featuredImage {
                  node {
                      localFile {
                          childImageSharp {
                              fluid(maxHeight: 440) {
                                  ...GatsbyImageSharpFluid_withWebp_noBase64
                              }
                          }
                      }
                  }
                }
            }
        },
    }
`
